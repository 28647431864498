import type { Query as Query_ibfxmh } from '../pages/menus/[menuCategory]/index.page';
import type { OptionalQuery as OptionalQuery_yb4lov } from '../pages/welcome/index.page';

export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "admin": {
    "passcode": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/passcode' as const, hash: url?.hash })
    },
    "select_seat": {
      "visitor_attribute": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/select-seat/visitor-attribute' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/select-seat' as const, hash: url?.hash })
    },
    "select_visit": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/select-visit' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin' as const, hash: url?.hash })
  },
  "debug": {
    "auth": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/debug/auth' as const, hash: url?.hash })
    }
  },
  "menus": {
    _menuCategory: (menuCategory: string | number) => ({
      "order_history": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/menus/[menuCategory]/order-history' as const, query: { menuCategory }, hash: url?.hash })
      },
      $url: (url: { query: Query_ibfxmh, hash?: string | undefined }) => ({ pathname: '/menus/[menuCategory]' as const, query: { menuCategory, ...url.query }, hash: url.hash })
    })
  },
  "welcome": {
    $url: (url?: { query?: OptionalQuery_yb4lov | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/welcome' as const, query: url?.query, hash: url?.hash })
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash })
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  circles_svg: '/circles.svg',
  next_svg: '/next.svg',
  speria_logo_png: '/speria_logo.png',
  speria_logo_2x_png: '/speria_logo_2x.png',
  speria_logo_3x_png: '/speria_logo_3x.png',
  turborepo_svg: '/turborepo.svg',
  vercel_svg: '/vercel.svg'
} as const;

export type StaticPath = typeof staticPath;
