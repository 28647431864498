import { ApolloProvider } from "@apollo/client";
import { NextPage } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import { ReactElement, ReactNode } from "react";

import { FontsLoader } from "../components/FontLoader";

import { AuthorizedGuard } from "src/components/AuthorizedGuard";
import { useApollo } from "src/hooks/useApollo";
import { AdminAuthProvider } from "src/providers/AdminAuthProvider";
import { AuthProvider } from "src/providers/AuthProvider";
import { MenuChakraProvider } from "src/providers/MenuChakraProvider";
import "./styles.css";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const client = useApollo(pageProps.initialApolloState);
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <title>Speria for Menu</title>
        <meta name="viewport" content="initial-scale=1.0, width=800" />
        <meta name="robots" content="noindex" />
      </Head>

      <ApolloProvider client={client}>
        <MenuChakraProvider>
          <AuthProvider>
            <AdminAuthProvider>
              <FontsLoader>
                <AuthorizedGuard>
                  {getLayout(<Component {...pageProps} />)}
                </AuthorizedGuard>
              </FontsLoader>
            </AdminAuthProvider>
          </AuthProvider>
        </MenuChakraProvider>
      </ApolloProvider>
    </>
  );
};

export default App;
