import { useLazyQuery, useMutation } from "@apollo/client";
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { useAsyncFn } from "react-use";

import { getFragmentData } from "src/gql/__generated__";
import {
  AuthProvider_DebugCreateAuthSessionDocument,
  AuthProvider_ShopUserDocument,
  AuthProvider_ShopUserFragmentDoc,
} from "src/gql/__generated__/graphql";

export type ShopUser = {
  id: string;
  email: string;
  shops: {
    id: string;
    name: string;
  }[];
  currentShop: {
    id: string;
    name: string;
  } | null;
};

type SignInResultSuccess = {
  status: "success";
};
type SignInResultError = {
  status: "error";
  message: string;
};

type SignInResult = SignInResultSuccess | SignInResultError;

type AuthState =
  | {
      status: "authorized";
      shopUser: ShopUser;
    }
  | {
      status: "unauthorized";
      shopUser: null;
    }
  | {
      status: "unknown";
      shopUser: null;
    };

type SignInForDebugRequest = {
  email: string;
};

type ContextValue = {
  authState: AuthState;
  isLoading: boolean;
  check: () => Promise<void>;
  signInForDebug: (request: SignInForDebugRequest) => Promise<SignInResult>;
};

const AuthContext = createContext<ContextValue>({
  authState: {
    status: "unknown",
    shopUser: null,
  },
  isLoading: false,
  check: () => {
    throw new Error("Not implemented");
  },
  signInForDebug: () => {
    throw new Error("Not implemented");
  },
});

export const AuthProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>({
    status: "unknown",
    shopUser: null,
  });
  const [debugCreateAuthSession] = useMutation(
    AuthProvider_DebugCreateAuthSessionDocument,
  );

  const [fetchShopUser] = useLazyQuery(AuthProvider_ShopUserDocument);

  const check = useCallback(async () => {
    const result = await fetchShopUser();
    if (!result.data?.currentShopUser) {
      setAuthState({
        status: "unauthorized",
        shopUser: null,
      });
      return;
    }

    const shopUser = getFragmentData(
      AuthProvider_ShopUserFragmentDoc,
      result.data.currentShopUser,
    );

    setAuthState({
      status: "authorized",
      shopUser,
    });
  }, [fetchShopUser]);

  const signInForDebug = useCallback(
    async ({ email }: SignInForDebugRequest): Promise<SignInResult> => {
      const result = await debugCreateAuthSession({
        variables: {
          input: {
            email,
          },
        },
      });

      if (!result.data || result.errors) {
        const message =
          result.errors?.[0]?.message ??
          "ログインに失敗しました。再度ログインを行ってください。";

        return {
          status: "error",
          message,
        };
      }

      const shopUser = getFragmentData(
        AuthProvider_ShopUserFragmentDoc,
        result.data.debugCreateAuthSession,
      );

      setAuthState({
        status: "authorized",
        shopUser,
      });

      return {
        status: "success",
      };
    },
    [debugCreateAuthSession],
  );

  const [doCheckState, doCheck] = useAsyncFn(check, [check]);
  const [doSignInForDebugState, doSignInForDebug] = useAsyncFn(signInForDebug, [
    signInForDebug,
  ]);

  const isLoading = doCheckState.loading || doSignInForDebugState.loading;

  return (
    <AuthContext.Provider
      value={{
        authState,
        isLoading,
        check: doCheck,
        signInForDebug: doSignInForDebug,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
