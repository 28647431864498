import { useCallback } from "react";

const CLIENT_ID = "menu";

export const useIdApp = () => {
  const redirectToSignInUrl = useCallback(
    (options?: { returnUrl?: string }) => {
      const returnUrl = options?.returnUrl ?? window.location.href;
      const query = new URLSearchParams({
        client_id: CLIENT_ID,
        redirect_uri: returnUrl,
      }).toString();
      const host = process.env.NEXT_PUBLIC_ID_HOST;
      const url = `${host}/?${query}`;

      window.location.href = url;
    },
    [],
  );

  const redirectToSignOutUrl = useCallback(
    (options?: { returnUrl?: string }) => {
      const returnUrl = options?.returnUrl ?? window.location.href;
      const query = new URLSearchParams({
        client_id: CLIENT_ID,
        redirect_uri: returnUrl,
      }).toString();
      const host = process.env.NEXT_PUBLIC_ID_HOST;
      const url = `${host}/sign-out?${query}`;

      window.location.href = url;
    },
    [],
  );

  const redirectToSelectShopUrl = useCallback(
    (options?: { returnUrl?: string }) => {
      const returnUrl = options?.returnUrl ?? window.location.href;
      const query = new URLSearchParams({
        client_id: CLIENT_ID,
        redirect_uri: returnUrl,
      }).toString();
      const host = process.env.NEXT_PUBLIC_ID_HOST;
      const url = `${host}/select-shop?${query}`;

      window.location.href = url;
    },
    [],
  );

  return {
    redirectToSignInUrl,
    redirectToSignOutUrl,
    redirectToSelectShopUrl,
  };
};
