/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
};

/** Autogenerated input type of ApplyVisitSession */
export type ApplyVisitSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  visitId: Scalars["String"]["input"];
};

/** Autogenerated input type of CallStaff */
export type CallStaffInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  requestType?: StaffCallRequestType;
};

export type Catalog = {
  __typename?: "Catalog";
  category: Maybe<CatalogCategory>;
  categoryOrdinal: Scalars["Float"]["output"];
  /** category が Course の時に存在する */
  courseItems: Maybe<Array<CourseItem>>;
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl: Maybe<Scalars["String"]["output"]>;
  invisible: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  /** subCategory が Wine の時に存在する */
  pairedFoods: Maybe<Array<Scalars["String"]["output"]>>;
  recommended: Scalars["Boolean"]["output"];
  subCategory: CatalogSubCategory;
  variations: Array<CatalogVariation>;
};

export type CatalogCategory = "Beverage" | "Course" | "Food";

export type CatalogSubCategory =
  | "Appetizer"
  | "BottleWineRed"
  | "BottleWineRose"
  | "BottleWineSparkling"
  | "BottleWineWhite"
  | "Course"
  | "CourseOption"
  | "Dessert"
  | "DimSum"
  | "GlassWine"
  | "Handy"
  | "LunchMenu"
  | "LunchSideMenu"
  | "Main"
  | "NonAlcoholic"
  | "NoodleRice"
  | "OtherDrink"
  | "Tea";

export type CatalogVariation = {
  __typename?: "CatalogVariation";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  price: Scalars["Int"]["output"];
};

export type CourseItem = {
  __typename?: "CourseItem";
  description: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
};

export type CreateCourseOrderItemCourseMenuDetailDetailInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type CreateCourseOrderItemCourseMenuDetailInput = {
  drinks: Array<CreateCourseOrderItemCourseMenuDetailDetailInput>;
  foods: Array<CreateCourseOrderItemCourseMenuDetailDetailInput>;
};

export type CreateCourseOrderItemInput = {
  courseCatalogId: Scalars["ID"]["input"];
  courseMenuDetail?: InputMaybe<CreateCourseOrderItemCourseMenuDetailInput>;
  courseOptionCatalogId?: InputMaybe<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  orderedFrom?: InputMaybe<OrderedFrom>;
  price: Scalars["Int"]["input"];
  quantity: Scalars["Int"]["input"];
};

/** Autogenerated input type of CreateMenuAdminAuthSession */
export type CreateMenuAdminAuthSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  passcode: Scalars["String"]["input"];
};

/** Autogenerated input type of CreateOrder */
export type CreateOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  courseOrderItems: Array<CreateCourseOrderItemInput>;
  orderItems: Array<CreateOrderItemInput>;
};

export type CreateOrderItemInput = {
  catalogId: Scalars["ID"]["input"];
  catalogVariationId: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  orderedFrom?: InputMaybe<OrderedFrom>;
  price: Scalars["Int"]["input"];
  quantity: Scalars["Int"]["input"];
};

/** Autogenerated input type of CreateVisit */
export type CreateVisitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  menuType: MenuType;
  purpose?: VisitPurpose;
  repeatStatus?: VisitRepeatStatus;
  tableIds: Array<Scalars["String"]["input"]>;
  visitorCount: Scalars["Int"]["input"];
};

export type Customer = {
  __typename?: "Customer";
  id: Scalars["ID"]["output"];
  profile: Maybe<Profile>;
};

/** Autogenerated input type of CreateAuthSession */
export type DebugCreateAuthSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
};

/** Autogenerated input type of DestroyMenuAdminAuthSession */
export type DestroyMenuAdminAuthSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Gender = "Female" | "Male" | "NotApplicable" | "Unknown";

export type Membership = {
  __typename?: "Membership";
  customer: Customer;
  id: Scalars["ID"]["output"];
};

export type MembershipVisit = {
  __typename?: "MembershipVisit";
  id: Scalars["ID"]["output"];
  membership: Membership;
};

export type MenuCategory = "Dinner" | "Lunch";

export type MenuType = "Dinner" | "Lunch";

export type Mutation = {
  __typename?: "Mutation";
  applyVisitSession: Maybe<Scalars["String"]["output"]>;
  callStaff: Scalars["Boolean"]["output"];
  createMenuAdminAuthSession: Scalars["Boolean"]["output"];
  createOrder: Order;
  createVisit: Maybe<Scalars["String"]["output"]>;
  debugCreateAuthSession: ShopUser;
  destroyMenuAdminAuthSession: Scalars["Boolean"]["output"];
  resetVisitSession: Maybe<Scalars["Boolean"]["output"]>;
  settle: Settlement;
};

export type MutationApplyVisitSessionArgs = {
  input: ApplyVisitSessionInput;
};

export type MutationCallStaffArgs = {
  input: CallStaffInput;
};

export type MutationCreateMenuAdminAuthSessionArgs = {
  input: CreateMenuAdminAuthSessionInput;
};

export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};

export type MutationCreateVisitArgs = {
  input: CreateVisitInput;
};

export type MutationDebugCreateAuthSessionArgs = {
  input: DebugCreateAuthSessionInput;
};

export type MutationDestroyMenuAdminAuthSessionArgs = {
  input: DestroyMenuAdminAuthSessionInput;
};

export type MutationResetVisitSessionArgs = {
  input: ResetVisitSessionInput;
};

export type MutationSettleArgs = {
  input: SettleInput;
};

export type Order = {
  __typename?: "Order";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  orderItems: Array<OrderItem>;
};

export type OrderCalculation = {
  __typename?: "OrderCalculation";
  subtotal: Scalars["Int"]["output"];
  tax: Scalars["Int"]["output"];
  totalAmount: Scalars["Int"]["output"];
};

export type OrderItem = {
  __typename?: "OrderItem";
  amount: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  price: Scalars["Int"]["output"];
  quantity: Scalars["Int"]["output"];
  status: OrderItemStatus;
  subCategory: CatalogSubCategory;
  variation: Maybe<Scalars["String"]["output"]>;
};

export type OrderItemStatus = "Canceled" | "Pending" | "Served";

export type OrderedFrom = "Menu" | "MenuRecommendation" | "StaffHandy";

export type Profile = {
  __typename?: "Profile";
  birthYear: Scalars["Int"]["output"];
  firstNameKana: Scalars["String"]["output"];
  gender: Gender;
  id: Scalars["ID"]["output"];
  lastNameKana: Scalars["String"]["output"];
  phone: Scalars["String"]["output"];
};

export type Query = {
  __typename?: "Query";
  /** Fetches a list of catalogs. */
  catalogs: Array<Catalog>;
  currentShop: Shop;
  currentShopUser: Maybe<ShopUser>;
  currentVisit: Maybe<Visit>;
  /** Check if the admin is logged in. */
  isMenuAdminAuthorized: Scalars["Boolean"]["output"];
  tables: Array<Table>;
  visits: Array<Visit>;
};

export type QueryCatalogsArgs = {
  menuCategory: InputMaybe<MenuCategory>;
};

/** Autogenerated input type of ResetVisitSession */
export type ResetVisitSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Seat = {
  __typename?: "Seat";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  table: Table;
};

export type ServiceCharge = {
  __typename?: "ServiceCharge";
  dinner: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  lunch: Scalars["Int"]["output"];
};

/** Autogenerated input type of Settle */
export type SettleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Settlement = {
  __typename?: "Settlement";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
};

export type Shop = {
  __typename?: "Shop";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  serviceCharge: ServiceCharge;
};

export type ShopUser = {
  __typename?: "ShopUser";
  currentShop: Maybe<Shop>;
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  shops: Array<Shop>;
};

export type StaffCallRequestType = "CallSommelier" | "CallStaff";

export type Table = {
  __typename?: "Table";
  category: TableCategory;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  seats: Array<Seat>;
  status: TableVisitStatus;
};

export type TableCategory = "Counter" | "TableFormat";

export type TableVisitStatus = "Open" | "Taken";

export type Visit = {
  __typename?: "Visit";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  membershipVisits: Array<MembershipVisit>;
  orderCalculation: OrderCalculation;
  orders: Array<Order>;
  recommendations: Array<VisitRecommendation>;
  settlement: Maybe<Settlement>;
  shop: Shop;
  visitSeatMaps: Array<VisitSeatMap>;
  visitorCount: Scalars["Int"]["output"];
};

export type VisitPurpose = "Date" | "NonDate" | "Unknown";

export type VisitRecommendation = {
  __typename?: "VisitRecommendation";
  catalogId: Scalars["ID"]["output"];
  category: CatalogCategory;
  name: Scalars["String"]["output"];
  variationId: Scalars["ID"]["output"];
};

export type VisitRepeatStatus = "FirstTime" | "Repeat" | "Unknown";

export type VisitSeatMap = {
  __typename?: "VisitSeatMap";
  id: Scalars["ID"]["output"];
  seat: Seat;
};

export type AdminSelectSeatScreen_TableFragment = {
  __typename?: "Table";
  id: string;
  name: string;
  status: TableVisitStatus;
  category: TableCategory;
  seats: Array<{ __typename?: "Seat"; id: string; name: string }>;
} & { " $fragmentName"?: "AdminSelectSeatScreen_TableFragment" };

export type MenuHeader_VisitFragment = {
  __typename?: "Visit";
  id: string;
  visitSeatMaps: Array<{ __typename?: "VisitSeatMap"; id: string }>;
  membershipVisits: Array<{
    __typename?: "MembershipVisit";
    id: string;
    membership: {
      __typename?: "Membership";
      id: string;
      customer: {
        __typename?: "Customer";
        id: string;
        profile: {
          __typename?: "Profile";
          id: string;
          firstNameKana: string;
          lastNameKana: string;
        } | null;
      };
    };
  }>;
} & { " $fragmentName"?: "MenuHeader_VisitFragment" };

export type MenuHeader_ShopFragment = {
  __typename?: "Shop";
  id: string;
  name: string;
  serviceCharge: {
    __typename?: "ServiceCharge";
    id: string;
    lunch: number;
    dinner: number;
  };
} & { " $fragmentName"?: "MenuHeader_ShopFragment" };

export type CallStaffMutationVariables = Exact<{
  input: CallStaffInput;
}>;

export type CallStaffMutation = { __typename?: "Mutation"; callStaff: boolean };

export type OrderHistoryScreen_VisitFragment = {
  __typename?: "Visit";
  orders: Array<{
    __typename?: "Order";
    id: string;
    createdAt: string;
    orderItems: Array<{
      __typename?: "OrderItem";
      id: string;
      name: string;
      price: number;
      quantity: number;
      amount: number;
      subCategory: CatalogSubCategory;
      variation: string | null;
      status: OrderItemStatus;
    }>;
  }>;
  orderCalculation: {
    __typename?: "OrderCalculation";
    totalAmount: number;
    subtotal: number;
    tax: number;
  };
  settlement: {
    __typename?: "Settlement";
    id: string;
    createdAt: string;
  } | null;
} & { " $fragmentName"?: "OrderHistoryScreen_VisitFragment" };

export type CatalogUnitFragment = {
  __typename?: "Catalog";
  id: string;
  imageUrl: string | null;
  name: string;
  description: string | null;
  category: CatalogCategory | null;
  subCategory: CatalogSubCategory;
  categoryOrdinal: number;
  pairedFoods: Array<string> | null;
  recommended: boolean;
  invisible: boolean;
  courseItems: Array<{
    __typename?: "CourseItem";
    name: string;
    description: string | null;
  }> | null;
  variations: Array<{
    __typename?: "CatalogVariation";
    id: string;
    name: string;
    price: number;
  }>;
} & { " $fragmentName"?: "CatalogUnitFragment" };

export type ResetVisitSessionMutationVariables = Exact<{
  input: ResetVisitSessionInput;
}>;

export type ResetVisitSessionMutation = {
  __typename?: "Mutation";
  resetVisitSession: boolean | null;
};

export type FetchTablesDataQueryVariables = Exact<{ [key: string]: never }>;

export type FetchTablesDataQuery = {
  __typename?: "Query";
  tables: Array<
    { __typename?: "Table" } & {
      " $fragmentRefs"?: {
        AdminSelectSeatScreen_TableFragment: AdminSelectSeatScreen_TableFragment;
      };
    }
  >;
};

export type CreateVisitMutationVariables = Exact<{
  input: CreateVisitInput;
}>;

export type CreateVisitMutation = {
  __typename?: "Mutation";
  createVisit: string | null;
};

export type FetchVisitsDataQueryVariables = Exact<{ [key: string]: never }>;

export type FetchVisitsDataQuery = {
  __typename?: "Query";
  visits: Array<{
    __typename?: "Visit";
    id: string;
    visitorCount: number;
    createdAt: string;
    visitSeatMaps: Array<{
      __typename?: "VisitSeatMap";
      id: string;
      seat: {
        __typename?: "Seat";
        id: string;
        name: string;
        table: { __typename?: "Table"; id: string; name: string };
      };
    }>;
  }>;
};

export type ApplyVisitSessionMutationVariables = Exact<{
  input: ApplyVisitSessionInput;
}>;

export type ApplyVisitSessionMutation = {
  __typename?: "Mutation";
  applyVisitSession: string | null;
};

export type Menus_FetchCatalogsQueryVariables = Exact<{
  menuCategory: MenuCategory;
}>;

export type Menus_FetchCatalogsQuery = {
  __typename?: "Query";
  catalogs: Array<
    {
      __typename?: "Catalog";
      category: CatalogCategory | null;
      subCategory: CatalogSubCategory;
      recommended: boolean;
    } & { " $fragmentRefs"?: { CatalogUnitFragment: CatalogUnitFragment } }
  >;
  currentVisit: {
    __typename?: "Visit";
    id: string;
    recommendations: Array<{
      __typename?: "VisitRecommendation";
      name: string;
      catalogId: string;
      variationId: string;
      category: CatalogCategory;
    }>;
  } | null;
};

export type Menus_FetchCurrentVisitQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Menus_FetchCurrentVisitQuery = {
  __typename?: "Query";
  currentVisit:
    | ({ __typename?: "Visit" } & {
        " $fragmentRefs"?: {
          MenuHeader_VisitFragment: MenuHeader_VisitFragment;
        };
      })
    | null;
  currentShop: { __typename?: "Shop" } & {
    " $fragmentRefs"?: { MenuHeader_ShopFragment: MenuHeader_ShopFragment };
  };
};

export type CreateOrderMutationVariables = Exact<{
  input: CreateOrderInput;
}>;

export type CreateOrderMutation = {
  __typename?: "Mutation";
  createOrder: {
    __typename?: "Order";
    id: string;
    createdAt: string;
    orderItems: Array<{ __typename?: "OrderItem"; id: string; name: string }>;
  };
};

export type FetchCurrentVisitQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCurrentVisitQuery = {
  __typename?: "Query";
  currentVisit:
    | ({ __typename?: "Visit"; id: string } & {
        " $fragmentRefs"?: {
          OrderHistoryScreen_VisitFragment: OrderHistoryScreen_VisitFragment;
        };
      })
    | null;
};

export type SettleMutationVariables = Exact<{
  input: SettleInput;
}>;

export type SettleMutation = {
  __typename?: "Mutation";
  settle: { __typename?: "Settlement"; id: string; createdAt: string };
};

export type FetchIsMenuAdminAuthorizedQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchIsMenuAdminAuthorizedQuery = {
  __typename?: "Query";
  isMenuAdminAuthorized: boolean;
};

export type CreateMenuAdminAuthSessionMutationVariables = Exact<{
  input: CreateMenuAdminAuthSessionInput;
}>;

export type CreateMenuAdminAuthSessionMutation = {
  __typename?: "Mutation";
  createMenuAdminAuthSession: boolean;
};

export type DestroyMenuAdminAuthSessionMutationVariables = Exact<{
  input: DestroyMenuAdminAuthSessionInput;
}>;

export type DestroyMenuAdminAuthSessionMutation = {
  __typename?: "Mutation";
  destroyMenuAdminAuthSession: boolean;
};

export type AuthProvider_DebugCreateAuthSessionMutationVariables = Exact<{
  input: DebugCreateAuthSessionInput;
}>;

export type AuthProvider_DebugCreateAuthSessionMutation = {
  __typename?: "Mutation";
  debugCreateAuthSession: { __typename?: "ShopUser" } & {
    " $fragmentRefs"?: {
      AuthProvider_ShopUserFragment: AuthProvider_ShopUserFragment;
    };
  };
};

export type AuthProvider_ShopUserFragment = {
  __typename?: "ShopUser";
  id: string;
  email: string;
  shops: Array<{ __typename?: "Shop"; id: string; name: string }>;
  currentShop: { __typename?: "Shop"; id: string; name: string } | null;
} & { " $fragmentName"?: "AuthProvider_ShopUserFragment" };

export type AuthProvider_ShopUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AuthProvider_ShopUserQuery = {
  __typename?: "Query";
  currentShopUser:
    | ({ __typename?: "ShopUser" } & {
        " $fragmentRefs"?: {
          AuthProvider_ShopUserFragment: AuthProvider_ShopUserFragment;
        };
      })
    | null;
};

export const AdminSelectSeatScreen_TableFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdminSelectSeatScreen_Table" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Table" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminSelectSeatScreen_TableFragment, unknown>;
export const MenuHeader_VisitFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MenuHeader_Visit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "visitSeatMaps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membershipVisits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "membership" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "profile" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "firstNameKana",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastNameKana",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MenuHeader_VisitFragment, unknown>;
export const MenuHeader_ShopFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MenuHeader_Shop" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "serviceCharge" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lunch" } },
                { kind: "Field", name: { kind: "Name", value: "dinner" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MenuHeader_ShopFragment, unknown>;
export const OrderHistoryScreen_VisitFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OrderHistoryScreen_Visit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orderItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "price" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subCategory" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "variation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderCalculation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                { kind: "Field", name: { kind: "Name", value: "subtotal" } },
                { kind: "Field", name: { kind: "Name", value: "tax" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "settlement" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderHistoryScreen_VisitFragment, unknown>;
export const CatalogUnitFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CatalogUnit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Catalog" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "subCategory" } },
          { kind: "Field", name: { kind: "Name", value: "categoryOrdinal" } },
          { kind: "Field", name: { kind: "Name", value: "pairedFoods" } },
          { kind: "Field", name: { kind: "Name", value: "recommended" } },
          { kind: "Field", name: { kind: "Name", value: "invisible" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "courseItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "variations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CatalogUnitFragment, unknown>;
export const AuthProvider_ShopUserFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthProvider_ShopUser" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ShopUser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shops" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentShop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthProvider_ShopUserFragment, unknown>;
export const CallStaffDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CallStaff" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CallStaffInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "callStaff" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CallStaffMutation, CallStaffMutationVariables>;
export const ResetVisitSessionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ResetVisitSession" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ResetVisitSessionInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resetVisitSession" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetVisitSessionMutation,
  ResetVisitSessionMutationVariables
>;
export const FetchTablesDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchTablesData" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tables" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AdminSelectSeatScreen_Table" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdminSelectSeatScreen_Table" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Table" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchTablesDataQuery,
  FetchTablesDataQueryVariables
>;
export const CreateVisitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateVisit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateVisitInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createVisit" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateVisitMutation, CreateVisitMutationVariables>;
export const FetchVisitsDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchVisitsData" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "visits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visitorCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visitSeatMaps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seat" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "table" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchVisitsDataQuery,
  FetchVisitsDataQueryVariables
>;
export const ApplyVisitSessionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ApplyVisitSession" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ApplyVisitSessionInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "applyVisitSession" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplyVisitSessionMutation,
  ApplyVisitSessionMutationVariables
>;
export const Menus_FetchCatalogsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Menus_FetchCatalogs" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "menuCategory" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "MenuCategory" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "catalogs" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "menuCategory" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "menuCategory" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "category" } },
                { kind: "Field", name: { kind: "Name", value: "subCategory" } },
                { kind: "Field", name: { kind: "Name", value: "recommended" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CatalogUnit" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVisit" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recommendations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "catalogId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "variationId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CatalogUnit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Catalog" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "subCategory" } },
          { kind: "Field", name: { kind: "Name", value: "categoryOrdinal" } },
          { kind: "Field", name: { kind: "Name", value: "pairedFoods" } },
          { kind: "Field", name: { kind: "Name", value: "recommended" } },
          { kind: "Field", name: { kind: "Name", value: "invisible" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "courseItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "variations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Menus_FetchCatalogsQuery,
  Menus_FetchCatalogsQueryVariables
>;
export const Menus_FetchCurrentVisitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Menus_FetchCurrentVisit" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVisit" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "MenuHeader_Visit" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentShop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "MenuHeader_Shop" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MenuHeader_Visit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "visitSeatMaps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membershipVisits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "membership" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "profile" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "firstNameKana",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastNameKana",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MenuHeader_Shop" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "serviceCharge" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lunch" } },
                { kind: "Field", name: { kind: "Name", value: "dinner" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Menus_FetchCurrentVisitQuery,
  Menus_FetchCurrentVisitQueryVariables
>;
export const CreateOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateOrderInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orderItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOrderMutation, CreateOrderMutationVariables>;
export const FetchCurrentVisitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchCurrentVisit" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVisit" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "OrderHistoryScreen_Visit" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OrderHistoryScreen_Visit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orderItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "price" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subCategory" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "variation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderCalculation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                { kind: "Field", name: { kind: "Name", value: "subtotal" } },
                { kind: "Field", name: { kind: "Name", value: "tax" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "settlement" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchCurrentVisitQuery,
  FetchCurrentVisitQueryVariables
>;
export const SettleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Settle" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SettleInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "settle" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SettleMutation, SettleMutationVariables>;
export const FetchIsMenuAdminAuthorizedDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchIsMenuAdminAuthorized" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "isMenuAdminAuthorized" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchIsMenuAdminAuthorizedQuery,
  FetchIsMenuAdminAuthorizedQueryVariables
>;
export const CreateMenuAdminAuthSessionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateMenuAdminAuthSession" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateMenuAdminAuthSessionInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createMenuAdminAuthSession" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMenuAdminAuthSessionMutation,
  CreateMenuAdminAuthSessionMutationVariables
>;
export const DestroyMenuAdminAuthSessionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DestroyMenuAdminAuthSession" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DestroyMenuAdminAuthSessionInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "destroyMenuAdminAuthSession" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DestroyMenuAdminAuthSessionMutation,
  DestroyMenuAdminAuthSessionMutationVariables
>;
export const AuthProvider_DebugCreateAuthSessionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AuthProvider_DebugCreateAuthSession" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DebugCreateAuthSessionInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "debugCreateAuthSession" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AuthProvider_ShopUser" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthProvider_ShopUser" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ShopUser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shops" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentShop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthProvider_DebugCreateAuthSessionMutation,
  AuthProvider_DebugCreateAuthSessionMutationVariables
>;
export const AuthProvider_ShopUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AuthProvider_ShopUser" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentShopUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AuthProvider_ShopUser" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthProvider_ShopUser" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ShopUser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shops" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentShop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthProvider_ShopUserQuery,
  AuthProvider_ShopUserQueryVariables
>;
