/* eslint-disable */
import * as types from "./graphql";
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "fragment AdminSelectSeatScreen_Table on Table {\n  id\n  name\n  status\n  category\n  seats {\n    id\n    name\n  }\n}":
    types.AdminSelectSeatScreen_TableFragmentDoc,
  "fragment MenuHeader_Visit on Visit {\n  id\n  visitSeatMaps {\n    id\n  }\n  membershipVisits {\n    id\n    membership {\n      id\n      customer {\n        id\n        profile {\n          id\n          firstNameKana\n          lastNameKana\n        }\n      }\n    }\n  }\n}\n\nfragment MenuHeader_Shop on Shop {\n  id\n  name\n  serviceCharge {\n    id\n    lunch\n    dinner\n  }\n}\n\nmutation CallStaff($input: CallStaffInput!) {\n  callStaff(input: $input)\n}":
    types.MenuHeader_VisitFragmentDoc,
  "fragment OrderHistoryScreen_Visit on Visit {\n  orders {\n    id\n    createdAt\n    orderItems {\n      id\n      name\n      price\n      quantity\n      amount\n      subCategory\n      variation\n      status\n    }\n  }\n  orderCalculation {\n    totalAmount\n    subtotal\n    tax\n  }\n  settlement {\n    id\n    createdAt\n  }\n}":
    types.OrderHistoryScreen_VisitFragmentDoc,
  "fragment CatalogUnit on Catalog {\n  id\n  imageUrl\n  name\n  description\n  category\n  subCategory\n  categoryOrdinal\n  pairedFoods\n  recommended\n  invisible\n  courseItems {\n    name\n    description\n  }\n  variations {\n    id\n    name\n    price\n  }\n}":
    types.CatalogUnitFragmentDoc,
  "mutation ResetVisitSession($input: ResetVisitSessionInput!) {\n  resetVisitSession(input: $input)\n}":
    types.ResetVisitSessionDocument,
  "query FetchTablesData {\n  tables {\n    ...AdminSelectSeatScreen_Table\n  }\n}\n\nmutation CreateVisit($input: CreateVisitInput!) {\n  createVisit(input: $input)\n}":
    types.FetchTablesDataDocument,
  "query FetchVisitsData {\n  visits {\n    id\n    visitorCount\n    createdAt\n    visitSeatMaps {\n      id\n      seat {\n        id\n        name\n        table {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n\nmutation ApplyVisitSession($input: ApplyVisitSessionInput!) {\n  applyVisitSession(input: $input)\n}":
    types.FetchVisitsDataDocument,
  "query Menus_FetchCatalogs($menuCategory: MenuCategory!) {\n  catalogs(menuCategory: $menuCategory) {\n    category\n    subCategory\n    recommended\n    ...CatalogUnit\n  }\n  currentVisit {\n    id\n    recommendations {\n      name\n      catalogId\n      variationId\n      category\n    }\n  }\n}\n\nquery Menus_FetchCurrentVisit {\n  currentVisit {\n    ...MenuHeader_Visit\n  }\n  currentShop {\n    ...MenuHeader_Shop\n  }\n}\n\nmutation CreateOrder($input: CreateOrderInput!) {\n  createOrder(input: $input) {\n    id\n    createdAt\n    orderItems {\n      id\n      name\n    }\n  }\n}":
    types.Menus_FetchCatalogsDocument,
  "query FetchCurrentVisit {\n  currentVisit {\n    id\n    ...OrderHistoryScreen_Visit\n  }\n}\n\nmutation Settle($input: SettleInput!) {\n  settle(input: $input) {\n    id\n    createdAt\n  }\n}":
    types.FetchCurrentVisitDocument,
  "query FetchIsMenuAdminAuthorized {\n  isMenuAdminAuthorized\n}\n\nmutation CreateMenuAdminAuthSession($input: CreateMenuAdminAuthSessionInput!) {\n  createMenuAdminAuthSession(input: $input)\n}\n\nmutation DestroyMenuAdminAuthSession($input: DestroyMenuAdminAuthSessionInput!) {\n  destroyMenuAdminAuthSession(input: $input)\n}":
    types.FetchIsMenuAdminAuthorizedDocument,
  "mutation AuthProvider_DebugCreateAuthSession($input: DebugCreateAuthSessionInput!) {\n  debugCreateAuthSession(input: $input) {\n    ...AuthProvider_ShopUser\n  }\n}\n\nfragment AuthProvider_ShopUser on ShopUser {\n  id\n  email\n  shops {\n    id\n    name\n  }\n  currentShop {\n    id\n    name\n  }\n}\n\nquery AuthProvider_ShopUser {\n  currentShopUser {\n    ...AuthProvider_ShopUser\n  }\n}":
    types.AuthProvider_DebugCreateAuthSessionDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "fragment AdminSelectSeatScreen_Table on Table {\n  id\n  name\n  status\n  category\n  seats {\n    id\n    name\n  }\n}",
): (typeof documents)["fragment AdminSelectSeatScreen_Table on Table {\n  id\n  name\n  status\n  category\n  seats {\n    id\n    name\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "fragment MenuHeader_Visit on Visit {\n  id\n  visitSeatMaps {\n    id\n  }\n  membershipVisits {\n    id\n    membership {\n      id\n      customer {\n        id\n        profile {\n          id\n          firstNameKana\n          lastNameKana\n        }\n      }\n    }\n  }\n}\n\nfragment MenuHeader_Shop on Shop {\n  id\n  name\n  serviceCharge {\n    id\n    lunch\n    dinner\n  }\n}\n\nmutation CallStaff($input: CallStaffInput!) {\n  callStaff(input: $input)\n}",
): (typeof documents)["fragment MenuHeader_Visit on Visit {\n  id\n  visitSeatMaps {\n    id\n  }\n  membershipVisits {\n    id\n    membership {\n      id\n      customer {\n        id\n        profile {\n          id\n          firstNameKana\n          lastNameKana\n        }\n      }\n    }\n  }\n}\n\nfragment MenuHeader_Shop on Shop {\n  id\n  name\n  serviceCharge {\n    id\n    lunch\n    dinner\n  }\n}\n\nmutation CallStaff($input: CallStaffInput!) {\n  callStaff(input: $input)\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "fragment OrderHistoryScreen_Visit on Visit {\n  orders {\n    id\n    createdAt\n    orderItems {\n      id\n      name\n      price\n      quantity\n      amount\n      subCategory\n      variation\n      status\n    }\n  }\n  orderCalculation {\n    totalAmount\n    subtotal\n    tax\n  }\n  settlement {\n    id\n    createdAt\n  }\n}",
): (typeof documents)["fragment OrderHistoryScreen_Visit on Visit {\n  orders {\n    id\n    createdAt\n    orderItems {\n      id\n      name\n      price\n      quantity\n      amount\n      subCategory\n      variation\n      status\n    }\n  }\n  orderCalculation {\n    totalAmount\n    subtotal\n    tax\n  }\n  settlement {\n    id\n    createdAt\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "fragment CatalogUnit on Catalog {\n  id\n  imageUrl\n  name\n  description\n  category\n  subCategory\n  categoryOrdinal\n  pairedFoods\n  recommended\n  invisible\n  courseItems {\n    name\n    description\n  }\n  variations {\n    id\n    name\n    price\n  }\n}",
): (typeof documents)["fragment CatalogUnit on Catalog {\n  id\n  imageUrl\n  name\n  description\n  category\n  subCategory\n  categoryOrdinal\n  pairedFoods\n  recommended\n  invisible\n  courseItems {\n    name\n    description\n  }\n  variations {\n    id\n    name\n    price\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "mutation ResetVisitSession($input: ResetVisitSessionInput!) {\n  resetVisitSession(input: $input)\n}",
): (typeof documents)["mutation ResetVisitSession($input: ResetVisitSessionInput!) {\n  resetVisitSession(input: $input)\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query FetchTablesData {\n  tables {\n    ...AdminSelectSeatScreen_Table\n  }\n}\n\nmutation CreateVisit($input: CreateVisitInput!) {\n  createVisit(input: $input)\n}",
): (typeof documents)["query FetchTablesData {\n  tables {\n    ...AdminSelectSeatScreen_Table\n  }\n}\n\nmutation CreateVisit($input: CreateVisitInput!) {\n  createVisit(input: $input)\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query FetchVisitsData {\n  visits {\n    id\n    visitorCount\n    createdAt\n    visitSeatMaps {\n      id\n      seat {\n        id\n        name\n        table {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n\nmutation ApplyVisitSession($input: ApplyVisitSessionInput!) {\n  applyVisitSession(input: $input)\n}",
): (typeof documents)["query FetchVisitsData {\n  visits {\n    id\n    visitorCount\n    createdAt\n    visitSeatMaps {\n      id\n      seat {\n        id\n        name\n        table {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n\nmutation ApplyVisitSession($input: ApplyVisitSessionInput!) {\n  applyVisitSession(input: $input)\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query Menus_FetchCatalogs($menuCategory: MenuCategory!) {\n  catalogs(menuCategory: $menuCategory) {\n    category\n    subCategory\n    recommended\n    ...CatalogUnit\n  }\n  currentVisit {\n    id\n    recommendations {\n      name\n      catalogId\n      variationId\n      category\n    }\n  }\n}\n\nquery Menus_FetchCurrentVisit {\n  currentVisit {\n    ...MenuHeader_Visit\n  }\n  currentShop {\n    ...MenuHeader_Shop\n  }\n}\n\nmutation CreateOrder($input: CreateOrderInput!) {\n  createOrder(input: $input) {\n    id\n    createdAt\n    orderItems {\n      id\n      name\n    }\n  }\n}",
): (typeof documents)["query Menus_FetchCatalogs($menuCategory: MenuCategory!) {\n  catalogs(menuCategory: $menuCategory) {\n    category\n    subCategory\n    recommended\n    ...CatalogUnit\n  }\n  currentVisit {\n    id\n    recommendations {\n      name\n      catalogId\n      variationId\n      category\n    }\n  }\n}\n\nquery Menus_FetchCurrentVisit {\n  currentVisit {\n    ...MenuHeader_Visit\n  }\n  currentShop {\n    ...MenuHeader_Shop\n  }\n}\n\nmutation CreateOrder($input: CreateOrderInput!) {\n  createOrder(input: $input) {\n    id\n    createdAt\n    orderItems {\n      id\n      name\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query FetchCurrentVisit {\n  currentVisit {\n    id\n    ...OrderHistoryScreen_Visit\n  }\n}\n\nmutation Settle($input: SettleInput!) {\n  settle(input: $input) {\n    id\n    createdAt\n  }\n}",
): (typeof documents)["query FetchCurrentVisit {\n  currentVisit {\n    id\n    ...OrderHistoryScreen_Visit\n  }\n}\n\nmutation Settle($input: SettleInput!) {\n  settle(input: $input) {\n    id\n    createdAt\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query FetchIsMenuAdminAuthorized {\n  isMenuAdminAuthorized\n}\n\nmutation CreateMenuAdminAuthSession($input: CreateMenuAdminAuthSessionInput!) {\n  createMenuAdminAuthSession(input: $input)\n}\n\nmutation DestroyMenuAdminAuthSession($input: DestroyMenuAdminAuthSessionInput!) {\n  destroyMenuAdminAuthSession(input: $input)\n}",
): (typeof documents)["query FetchIsMenuAdminAuthorized {\n  isMenuAdminAuthorized\n}\n\nmutation CreateMenuAdminAuthSession($input: CreateMenuAdminAuthSessionInput!) {\n  createMenuAdminAuthSession(input: $input)\n}\n\nmutation DestroyMenuAdminAuthSession($input: DestroyMenuAdminAuthSessionInput!) {\n  destroyMenuAdminAuthSession(input: $input)\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "mutation AuthProvider_DebugCreateAuthSession($input: DebugCreateAuthSessionInput!) {\n  debugCreateAuthSession(input: $input) {\n    ...AuthProvider_ShopUser\n  }\n}\n\nfragment AuthProvider_ShopUser on ShopUser {\n  id\n  email\n  shops {\n    id\n    name\n  }\n  currentShop {\n    id\n    name\n  }\n}\n\nquery AuthProvider_ShopUser {\n  currentShopUser {\n    ...AuthProvider_ShopUser\n  }\n}",
): (typeof documents)["mutation AuthProvider_DebugCreateAuthSession($input: DebugCreateAuthSessionInput!) {\n  debugCreateAuthSession(input: $input) {\n    ...AuthProvider_ShopUser\n  }\n}\n\nfragment AuthProvider_ShopUser on ShopUser {\n  id\n  email\n  shops {\n    id\n    name\n  }\n  currentShop {\n    id\n    name\n  }\n}\n\nquery AuthProvider_ShopUser {\n  currentShopUser {\n    ...AuthProvider_ShopUser\n  }\n}"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
